.ticket {
    position: relative;
}

.pendingTicket {
    cursor: unset;
}

.noTicketsDiv {
    display: flex;
    height: 100px;
    margin: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.noTicketsText {
    text-align: center;
    color: rgb(104, 121, 146);
    font-size: 14px;
    line-height: 1.4;
}

.noTicketsTitle {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    margin: 0px;
}

.closedBadge {
    margin-bottom: 5px;
    margin-left: 7px;
}

.closedLabel {
    padding: 5px;
}

.contactLastMessage {
    padding-right: 20px;
}

.contactLastMessageClosed {
    padding-right: 20px;
    margin-left: 25px;
}

.ticketQueueColor {
    flex: none;
    width: 8px;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
}

.userTag {
    position: absolute;
    margin-right: 5px;
    right: 5px;
    bottom: 5px;
    background: #2576D2;
    color: #ffffff;
    border: 1px solid #CCC;
    padding: 1px 5px;
    border-radius: 10px;
    font-size: 0.9em;
}

.gridWithCustomSpacing {
    width: calc(100% + 32px) !important;
    margin: -30px !important;
}

.badgeStylePending {
    color: white;
    background-color: #4caf50;
    position: absolute !important;
    right: -90px !important;
    top: -3px !important;
}

.acceptButton {
    position: absolute;
    left: 74%;
    padding: 0px;
    bottom: 9px;
    text-transform: none;
    font-weight: 500;
}

.lastMessageTime {
    justify-self: flex-end;
    margin-left: 47px;
    letter-spacing: -1px;
}

.lastMessageDate {
    margin-left: 15px;
    letter-spacing: -1px;
}

.box-tickets {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}

.box-lastMessage-lastTime {
    margin-bottom: -13px !important;
}

.acceptButton {
    position: absolute;
    left: 74%;
    bottom: 9px;
    text-transform: none;
    font-weight: 500;
}

.box-button-accept {
    position: absolute;
    left: 67%;
    bottom: -25%;
}

.box-badge {
    position: absolute;
    left: 70%;
    bottom: -11%;
}


.no-tickets-text {
    text-align: center;
    color: rgb(104, 121, 146);
    font-size: 14px;
    line-height: 1.4;
}

.no-tickets-title {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    margin: 0px;
}

.no-tickets-div {
    display: flex;
    height: 100px;
    margin: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.empty-tickets-message-container {
    background-color: red;
}

/* ... Seu CSS original acima ... */

/* Acrescente as classes .noBadge e .hasBadge para lidar com o hover */
.noBadge:hover .whatsappBox {
    color: #000 !important;
    margin-right: -100px !important;
}

.hasBadge:hover .whatsappBox {
    color: #000 !important;
    margin-right: -66px !important;
}

.reopenButton {
    margin-left: -12px !important;
    margin-bottom: -5px !important;
}

.hasBadge:hover .reopenButton {
    margin-bottom: -25px !important;
    margin-top: 34px !important;
    margin-left: -12px !important;
}

.dropdownItem:hover {
    background-color: #f1f1f1;
}


.hoverPointer {
    cursor: pointer;
}

.customDropdownPending {
    transform: scale(0);
    transition: transform 0.2s ease, opacity 0.2s ease;
    opacity: 0;
}

.customDropdownPending.open {
    transform: scale(1);
    opacity: 1;
}